import dynamic from 'next/dynamic';
import { HEADER } from '@panshilab/utils';
import { useOffSetTop } from '@panshilab/components';
const Header = dynamic(() => import('./Header'), { ssr: false });

type Props = {
  children?: React.ReactNode;
};

export default function SimpleLayout({ children }: Props) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

      {children}
    </>
  );
}
